import React from 'react';
import BroadcastMessagesForm from './BroadcastMessagesForm';

const BroadcastTab = () => {
  return (
    <div>
      <BroadcastMessagesForm />
    </div>
  );
};

export default BroadcastTab;
